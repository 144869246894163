<template>
  <div>
    <video autoplay loop :muted="muted" controls playsinline class="fullscreen-background-video" :controlsList="['nodownload']">
      <source src="~@/images/Home.mp4" type="video/mp4" />
    </video>
  </div>
</template>
<script>
export default {
  data () {
    return {
      muted: true
    }
  }
}

</script>
<style>
video{
  width: 100%;
  height: calc(100vh - 80px);
}
</style>
