<template>
  <div>
    <div class="regalway"></div>
    <div class="TopBg"></div>
    <div class="NavigationBar">
      <div class="NavigationBar__item" @click="imageName = 'Home'">
        <div class="iconfont">&#xe665;</div>
        <span>Home</span>
      </div>
      <div class="NavigationBar__item">
        <div class="iconfont">&#xe611;</div>
        <div class="menu">
          <span v-on:mouseover="MouseOver">Products</span>
          <div class="dropdown">
            <select v-model="imageName" id="student" @change="recoverSize" size="3">
              <option
                v-for="item in options"
                :key="item.value"
                :value="item.value"
              >
                {{ item.text }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="NavigationBar__item" @click="imageName = 'AboutUs'">
        <div class="iconfont">&#xe605;</div>
        <span>About Us</span>
      </div>
      <div class="NavigationBar__item" @click="imageName = 'ContactUs'">
        <div class="iconfont">&#xe64a;</div>
        Contact Us
      </div>
    </div>
    <Home v-show="imageName == 'Home'"></Home>
    <div v-show="imageName != 'Home'" class="BodyDisplay" :class="[imageName]"  ></div>
    <div class="BottomPart"></div>
    <div class="Copyright">
      
    </div>
  </div>
</template>
<script>
import Home from '@/views/home/home.vue'
export default {
  components: { Home },
  data () {
    return {
      imageName: 'Home', // 用于绑定下拉菜单的选中值

      options: [
        // 下拉菜单的选项
        { value: 'Locomotives', text: 'Locomotives' },
        { value: 'BuildingsAccessories', text: 'Buildings & Accessories' },
        { value: 'FreightCarsCaboose', text: 'FreightCars & Caboose' }
      ]
    }
  },
  methods: {
    MouseOver () {
      console.log('鼠标移入')
      const element = document.getElementById('student')
      console.log(element)
      element.focus()
      element.setAttribute('size', this.options.length)
    },
    recoverSize () {
      document.getElementById('student').setAttribute('size', 0)
    }
  }
}
</script>
<style lang="scss">
.AboutUs{
  background-image: url("~@/images/AboutUs.png"); /* 替换为您的图片路径 */
  background-size: 100% 100%; /* 宽度和高度设置为100% */
}
.BuildingsAccessories{
  background-image: url("~@/images/BuildingsAccessories.png"); /* 替换为您的图片路径 */
  background-size: 100% 100%; /* 宽度和高度设置为100% */
}
.ContactUs{
  background-image: url("~@/images/ContactUs.png"); /* 替换为您的图片路径 */
  background-size: 100% 100%; /* 宽度和高度设置为100% */
}
.FreightCarsCaboose{
  background-image: url("~@/images/FreightCarsCaboose.png"); /* 替换为您的图片路径 */
  background-size: 100% 100%; /* 宽度和高度设置为100% */
}
.Locomotives{
  background-image: url("~@/images/Locomotives.png"); /* 替换为您的图片路径 */
  background-size: 100% 100%; /* 宽度和高度设置为100% */
}
.BodyDisplay {
  display: flex;
  box-sizing: border-box;
  position: absolute;
  top: 95px;
  padding: 0 100px;

  // background-image:var(--imageUrl);
  background-repeat: no-repeat; /* 防止背景图片重复 */
  background-size: contain;
  background-position: center;
  // height: 700px; /* 设置一个高度 */
  width: 100%; /* 宽度设置为100% */
  bottom: 30px;
}
.TopBg {
  display: flex;
  // margin: 0px 0px;
  height: 65px; /* 设置一个高度 */
  // width: 100%; /* 宽度设置为100% */
  background-image: url("~@/images/Top/rw.png"); /* 替换为您的图片路径 */
  // background-size: 100% 100%; /* 宽度和高度设置为100% */
  background-repeat: no-repeat; /* 防止背景图片重复 */
  background-size: cover;
  background-position: center;
}

.regalway{
  display: flex;
  box-sizing: border-box;
  position: absolute;
  padding: 0 110px;
  top: 3.5px;
  height:58px; /* 设置一个高度 */
 
  background-image: url('~@/images/regalway.png'); /* 替换为您的图片路径 */
  // background-image: url('~@/images/Copyright.png'); /* 替换为您的图片路径 */
  
  background-size: 100% 100%; /* 宽度和高度设置为100% */
  background-repeat: no-repeat; /* 防止背景图片重复 */
  background-size: contain;
  // background-size: cover;
  background-position: center;
}

.NavigationBar {
  display: flex;
  box-sizing: border-box;
  position: absolute;
  padding: 0 220px;
  left: 0;
  right: 0;
  top: 65px;
  height: 30px;
  font-size: 18px;
  // font-weight: bold;
  // font-style: italic;
  color: rgb(255, 255, 255);
  font-family: "Helvetica", "Arial", sans-serif;
  // border-bottom: 1px solid #f1f1f1;
  background: rgb(80, 133, 197);
  // background: rgb(15, 68, 133);
  z-index: 999;
  .NavigationBar__item {
    flex: 1;
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    cursor:pointer;
    .iconfont {
      font-size: 21px;
      padding: 0 5px 0 0;
    }
  }
}

.menu {
  // width: 100%;
  height: 30px;
  // background: #2386A3;
  line-height: 30px;
  // text-align: center;
  // padding: 0 0 0 5px ;
  position: relative;
  overflow: hidden;
}
.menu:hover {
  overflow: visible;
  // background: #2386A3;
  // padding: 0 0 0 5px ;
  color: white;
  z-index: 999;
  cursor: pointer;
}
.dropdown {
  width: 0;
  // padding: 0 5px 0 5px;
  background: rgb(80, 133, 197);
}
.drop {
  // padding: 0 5px 0 5px;
  background: rgb(80, 133, 197); /* 设置下拉菜单的背景颜色 */
}
.BottomPart {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 30px;
  background: rgb(80, 133, 197);
  // background: rgb(15, 68, 133);
}

.Copyright {
  display: flex;
  // box-sizing: border-box;
  position: absolute;
  // padding: 10px 0 5px 0x;
  // top: 12px;
  left: 0;
  right: 0;
  bottom: 6px;
  height: 16px;
  // height: 20px; /* 设置一个高度 */
  bottom: 10;
  background-image: url('~@/images/Copyright.png'); /* 替换为您的图片路径 */
  background-size: 100% 100%; /* 宽度和高度设置为100% */
  background-repeat: no-repeat; /* 防止背景图片重复 */
  background-size: contain;
  background-position: center;
}
</style>
